






import { Component, Prop, Vue } from 'vue-property-decorator'

type BaseTitleType = 'div' | 'h1' | 'h2' | 'h3'

@Component({
  name: 'BaseTitle',
})
export default class BaseTitle extends Vue {
  @Prop({ default: 'div' })
  tag!: BaseTitleType
}
